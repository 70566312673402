<template>
  <div style="padding: 30px">
    <div
      style="
        display: grid;
        grid-template-columns: 80% 20%;
        padding-bottom: 20px;
        border-bottom: 1px solid #e5e5e5;
      "
    >
      <div>
        <img
          src="@/assets/img/logoMbus.png"
          width="60px"
          height="45px"
          alt="logo"
        />
        <div
          style="
            display: grid;
            grid-template-columns: 36px 32px max-content;
            height: 90px;
            margin-top: 20px;
          "
        >
          <div>
            <p
              style="
                color: #1b1b1b;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin: 0;
              "
            >
              {{ ticket?.departure_stations?.arrival }}
            </p>
            <p
              style="
                color: #1b1b1b;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-top: 45px;
              "
            >
              {{ ticket?.arrival_stations?.arrival }}
            </p>
          </div>
          <div
            style="
              display: grid;
              place-items: center;
              height: 68px;
              margin: 4px 0px;
            "
          >
            <div
              style="
                border: 2px solid #b5b5b5;
                border-radius: 50%;
                width: 12px;
                height: 12px;
              "
            ></div>
            <div style="background: #b5b5b5; width: 2px; height: 44px"></div>
            <div
              style="
                background: #b5b5b5;
                border-radius: 50%;
                width: 12px;
                height: 12px;
              "
            ></div>
          </div>
          <div>
            <p
              style="
                color: #1b1b1b;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin: 0;
                margin-bottom: 4px;
              "
            >
              {{ ticket?.departure_info?.name }}
            </p>
            <p
              style="
                color: #1b1b1b;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0;
              "
            >
              {{ ticket?.departure_stations?.bus_station?.address }}
            </p>
            <p
              style="
                color: #1b1b1b;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-top: 23px;
                margin-bottom: 0px;
                margin-bottom: 4px;
              "
            >
              {{ ticket?.arrival_info?.name }}
            </p>
            <p
              style="
                color: #1b1b1b;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0;
              "
            >
              {{ ticket?.arrival_stations?.bus_station?.address }}
            </p>
          </div>
        </div>
      </div>
      <div style="width: 130px; height: 130px">
        <VueQRCodeComponent
          :text="text"
          :size="130"
          color="#1b1b1b"
          render-as="svg"
        />
      </div>
    </div>
    <div
      style="
        display: grid;
        grid-template-columns: 50% 50%;
        border-bottom: 1px solid #e5e5e5;
      "
    >
      <div style="padding: 20px 20px 20px 0px; border-right: 1px solid #e5e5e5">
        <span
          style="
            color: #6f6f6f;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 4px;
          "
        >
          Маршрут
        </span>
        <p
          style="
            color: #1b1b1b;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 20px;
          "
        >
          {{ ticket?.trip_info?.route_info?.departure_info?.name }} -
          {{ ticket?.trip_info?.route_info?.destination_info?.name }}
        </p>
        <v-row no-gutters align="center">
          <v-col class="px-0 py-0">
            <span
              style="
                color: #6f6f6f;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 4px;
              "
            >
              Дата відправлення
            </span>
            <p
              style="
                color: #1b1b1b;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 20px;
              "
            >
              {{
                new Date(
                  addMinutesToDate(
                    ticket?.trip_info?.departure_date,
                    ticket?.departure_stations?.time_from_start
                  )
                ).toLocaleDateString("uk-UA", {
                  year: "numeric",
                  day: "numeric",
                  month: "2-digit",
                })
              }}
            </p>
          </v-col>
          <v-col class="px-0 py-0">
            <span
              style="
                color: #6f6f6f;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 4px;
              "
            >
              Дата прибуття
            </span>
            <p
              style="
                color: #1b1b1b;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 20px;
              "
            >
              {{
                new Date(
                  addMinutesToDate(
                    ticket?.trip_info?.departure_date,
                    ticket?.arrival_stations?.time_from_start
                  )
                ).toLocaleDateString("uk-UA", {
                  year: "numeric",
                  day: "numeric",
                  month: "2-digit",
                })
              }}
            </p>
          </v-col>
        </v-row>
        <span
          style="
            color: #6f6f6f;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 4px;
          "
        >
          В дорозі
        </span>
        <p
          style="
            color: #1b1b1b;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 20px;
          "
        >
          {{
            setDifferentTime(
              addMinutesToDate(
                ticket?.trip_info?.departure_date,
                ticket?.departure_stations?.time_from_start
              ),
              addMinutesToDate(
                ticket?.trip_info?.departure_date,
                ticket?.arrival_stations?.time_from_start
              )
            )
          }}
        </p>
        <span
          style="
            color: #6f6f6f;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 4px;
          "
        >
          Платформа відправлення
        </span>
        <p
          style="
            color: #1b1b1b;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          "
        >
          Платформа {{ ticket?.departure_stations?.platform }}
        </p>
      </div>
      <div style="padding: 20px 0px 20px 20px">
        <div style="display: grid; grid-template-columns: 50% 50%">
          <div>
            <span
              style="
                color: #6f6f6f;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 4px;
              "
            >
              Квиток №
            </span>
            <p
              style="
                color: #1b1b1b;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 20px;
              "
            >
              {{ indexTicket + 1 }}
            </p>
          </div>
          <div>
            <span
              style="
                color: #6f6f6f;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 4px;
              "
            >
              Тип
            </span>
            <p
              style="
                color: #1b1b1b;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 20px;
              "
            >
              {{ ticket?.ticket?.[indexTicket]?.discount_info?.name || 'Дорослий' }}
            </p>
          </div>
        </div>
        <div style="display: grid; grid-template-columns: 50% 50%">
          <div>
            <span
              style="
                color: #6f6f6f;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 4px;
              "
            >
              Ім’я
            </span>
            <p
              style="
                color: #1b1b1b;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 20px;
              "
            >
              {{ ticket?.ticket?.[indexTicket]?.last_name }}
            </p>
          </div>
          <div>
            <span
              style="
                color: #6f6f6f;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 4px;
              "
            >
              Прізвище
            </span>
            <p
              style="
                color: #1b1b1b;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 20px;
              "
            >
              {{ ticket?.ticket?.[indexTicket]?.first_name }}
            </p>
          </div>
        </div>
        <div style="display: grid; grid-template-columns: 50% 50%">
          <div>
            <span
              style="
                color: #6f6f6f;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 4px;
              "
            >
              Телефон
            </span>
            <p
              style="
                color: #1b1b1b;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 20px;
              "
            >
              {{
                ticket?.ticket?.[indexTicket]?.phone_number
                  | VMask("+##(###)#######")
              }}
            </p>
          </div>
          <div>
            <span
              style="
                color: #6f6f6f;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 4px;
              "
            >
              Місце
            </span>
            <p
              style="
                color: #1b1b1b;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 20px;
              "
            >
              Вільне
            </p>
          </div>
        </div>
        <span
          style="
            color: #6f6f6f;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 4px;
          "
        >
          Вартість
        </span>
        <p
          style="
            color: #1b1b1b;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 20px;
          "
        >
          {{ ticket?.ticket?.[indexTicket]?.price / 100 }} грн
        </p>
      </div>
    </div>
    <div
      style="
        margin-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #e5e5e5;
      "
    >
      <p
        style="
          color: #1b1b1b;
          font-family: Roboto;
          font-size: 10px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 1px;
          margin-bottom: 16px;
        "
      >
        Цей Посадочний документ є підставою для проїзду без звернення у касу.
        Посадка здійснюється за пред’явленням документа, який посвідчує особу.
      </p>
      <ul>
        <li
          style="
            color: #1b1b1b;
            font-family: Roboto;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 1px;
          "
        >
          Перевіряйте дату, номер і час відправлення автобуса. Поїздка пасажира
          починається з адреси, яка вказана в проїзному документі.
        </li>
        <li
          style="
            color: #1b1b1b;
            font-family: Roboto;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 1px;
          "
        >
          Повернення даного Посадочного документа можливе до відправлення
          автобуса. Детальніше про повернення дізнайтеся у службі підтримки або
          на сайті.
        </li>
        <li
          style="
            color: #1b1b1b;
            font-family: Roboto;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 1px;
          "
        >
          Модель автобуса у час відправлення може бути змінена.
        </li>
      </ul>
    </div>

    <div style="margin-top: 24px">
      <p
        style="
          color: #1b1b1b;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 1.2px;
        "
      >
        Послуга від MBus: щоб ваш багаж ненароком не переплутали, позначте його
        биркою
      </p>
      <div
        style="
          border: 1px dashed rgba(107, 124, 138, 0.6);
          width: 100%;
          height: 160px;
          display: flex;
          margin-top: 8px;
        "
      >
        <img
          src="@/assets/img/backgroundLine.png"
          width="100px"
          height="160px"
          alt="line"
        />
        <!-- TICKET CONTENT -->
        <div
          style="
            width: calc(100% - 200px);
            padding: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <div style="background: #fff; width: 100%">
            <div style="display: grid; grid-template-columns: 50% 50%">
              <div style="margin-right: 10px">
                <p
                  style="
                    margin-top: 5px;
                    margin-bottom: 2px;
                    color: #1b1b1b;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 1.4px;
                    height: 20px;
                  "
                >
                  {{ ticket?.ticket?.[indexTicket]?.first_name }}
                  {{ ticket?.ticket?.[indexTicket]?.last_name }}
                </p>
                <div
                  style="width: 100%; height: 1px; background: #6b7c8a"
                ></div>
                <span
                  style="
                    color: #1b1b1b;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 1px;
                  "
                >
                  Name/ Ім’я
                </span>
              </div>
              <div style="margin-left: 10px">
                <p
                  style="
                    margin-top: 5px;
                    margin-bottom: 2px;
                    color: #1b1b1b;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 1.4px;
                    height: 20px;
                  "
                >
                  {{
                    ticket?.ticket?.[indexTicket]?.phone_number
                      | VMask("+##(###)#######")
                  }}
                </p>
                <div
                  style="width: 100%; height: 1px; background: #6b7c8a"
                ></div>
                <span
                  style="
                    color: #1b1b1b;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 1px;
                  "
                >
                  Phone number/ Номер телефону
                </span>
              </div>
            </div>
            <div style="display: grid; grid-template-columns: 50% 50%">
              <div style="margin-right: 10px">
                <p
                  style="
                    margin-top: 5px;
                    margin-bottom: 2px;
                    color: #1b1b1b;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 1.4px;
                    height: 20px;
                  "
                >
                  {{ ticket?.arrival_info?.name }}
                </p>
                <div
                  style="width: 100%; height: 1px; background: #6b7c8a"
                ></div>
                <span
                  style="
                    color: #1b1b1b;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 1px;
                  "
                >
                  City/Місто
                </span>
              </div>
              <div style="margin-left: 10px">
                <p
                  style="
                    margin-top: 5px;
                    margin-bottom: 2px;
                    color: #1b1b1b;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 1.4px;
                    height: 20px;
                  "
                >
                  {{
                    ticket?.trip_info?.route_info?.route_cities.filter(
                      (city) => city.city_uuid == ticket?.arrival_city
                    )?.[0]?.bus_station?.address
                  }}
                </p>
                <div
                  style="width: 100%; height: 1px; background: #6b7c8a"
                ></div>
                <span
                  style="
                    color: #1b1b1b;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 1px;
                  "
                >
                  Address/Адреса
                </span>
              </div>
            </div>
            <div
              style="display: flex; justify-content: center; margin-top: 10px"
            >
              <img
                src="@/assets/img/logoMbus.png"
                width="60px"
                height="45px"
                alt="logo"
              />
            </div>
          </div>
        </div>
        <img
          src="@/assets/img/backgroundLine.png"
          width="100px"
          height="160px"
          alt="line"
        />
      </div>
      <p
        style="
          color: #1b1b1b;
          font-family: Roboto;
          font-size: 10px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 1px;
        "
      >
        <span style="color: #e43232">*</span> Для використання виріжте бирку по
        контуру або загніть непотрібну частину та прикріпіть на свій багаж(місце
        скріплення позначено)
      </p>
    </div>
  </div>
</template> 

<script>
import diffrentTimeMixin from "@/mixins/diffrentTimeMixin";
import VueQRCodeComponent from "vue-qrcode-component";
export default {
  mixins: [diffrentTimeMixin],
  components: { VueQRCodeComponent },
  data: () => ({
    quantity_adult: 0,
    quantity_up_to_4: 0,
    quantity_4_till_8: 0,
    quantity_student_isic: 0,
    quantity_over_60: 0,
    price_adult: 0,
    price_up_to_4: 0,
    price_4_till_8: 0,
    price_student_isic: 0,
    price_over_60: 0,
  }),
  props: {
    ticket: {
      require: true,
    },
    text: { require: true },
    isPdf: {
      require: true,
    },
    indexTicket: {
      require: true,
    },
  },
  mounted() {
    // if (this.ticket) {
    //   this.setQuantity();
    // }
    this.setTripTime();
  },
  methods: {
    setQuantity() {
      this.ticket.ticket.forEach((passanger) => {
        if (passanger.discount_type !== null) {
          switch (passanger.discount_type) {
            case "up_to_4":
              {
                this.quantity_up_to_4++;
                this.price_up_to_4 += passanger.price;
              }
              break;
            case "4_till_8":
              {
                this.quantity_4_till_8++;
                this.price_4_till_8 += passanger.price;
              }
              break;
            case "student_isic":
              {
                this.quantity_student_isic++;
                this.price_student_isic += passanger.price;
              }
              break;
            case "over_60":
              {
                this.quantity_over_60++;
                this.price_over_60 += passanger.price;
              }
              break;
          }
        } else {
          this.quantity_adult++;
          this.price_adult += passanger.price;
        }
      });
    },
  },
  watch: {
    ticket: {
      handler() {
        this.setQuantity();
      },
    },
  },
};
</script>

<style scoped>
</style>