<template>
  <v-col class="px-0 py-0">
    <button class="signInWithSocialBtn">
      <fb-signin-button
        :params="fbSignInParams"
        @success="onSignInSuccess"
        @error="onSignInError"
      >
        <v-row no-gutters align="center">
          <img
            src="@/assets/img/facebookIcon.png"
            alt="google icon"
            width="24px"
            height="24px"
            style="margin-right: 12px"
          />
          Ввійти через Facebook
        </v-row>
      </fb-signin-button>
    </button>

    <button class="signInWithSocialBtn" @click="openGoogleSignInModal">
      <img
        src="@/assets/img/google-icon.svg"
        alt="google icon"
        width="24px"
        height="24px"
        style="margin-right: 12px"
      />
      Ввійти через Google
    </button>
  </v-col>
</template>

<script>
import authMixin from "@/mixins/authMixin";

export default {
  mixins: [authMixin],
};
</script>

<style>
.signInWithSocialBtn {
  color: #4f545a;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  background: #fbfbfb;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25),
    0px 0px 0px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
}
</style>