<template>
  <div
    style="
      height: 80px;
      position: fixed;
      bottom: 0px;
      display: flex;
      background-color: rgb(18, 43, 62, 0.8);
      width: 100%;
      z-index: 101;
    "
    :style="$vuetify.breakpoint.smAndDown ? 'height:max-content;' : ''"
  >
    <v-main>
      <v-container>
        <v-row no-gutters align="center">
          <v-col
            v-if="!$vuetify.breakpoint.smAndDown"
            style="color: #fff; text-align: left; padding-right: 20px"
          >
            Використовуючи наш сайт, ви приймаєте умови, за якими ми
            використовуємо cookie-файли для аналізу даних та створення контенту
            (у тому числі рекламного) на основі ваших інтересів.
          </v-col>
          <v-col
            cols="12"
            v-if="$vuetify.breakpoint.smAndDown"
            style="color: #fff; text-align: left; padding-right: 20px; font-size: 12px;"
          >
            Використовуючи наш сайт, ви приймаєте умови, за якими ми
            використовуємо cookie-файли для аналізу даних та створення контенту
            (у тому числі рекламного) на основі ваших інтересів.
            <v-row no-gutters justify="center" style="margin-top: 8px;">
              <button class="confirmCookiesBtn" @click="acceptCookie">
                Я погоджуюсь
              </button>
            </v-row>
          </v-col>
          <button v-if="!$vuetify.breakpoint.smAndDown" class="confirmCookiesBtn" @click="acceptCookie">
            Я погоджуюсь
          </button>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  methods: {
    acceptCookie() {
      localStorage.setItem("cookies_yes", "yes");
      this.$emit("accept");
    },
  },
};
</script>

<style scoped>
.confirmCookiesBtn {
  color: #fff;
  background-color: #085895;
  padding: 10px 20px;
  line-height: 14px;
  border: 1px solid #085895;
  border-radius: 2px;
  width: max-content;
  height: 40px;
}
</style>