<template>
  <v-app>
    <v-idle
      v-if="loggedUser"
      @idle="onidle"
      hidden
      :events="['keydown', 'mousedown', 'touchstart']"
      :duration="$store.getters.loggedUser.timeout"
    />
    <bus-loading v-if="showLoader" />
    <!-- <Loader v-if="showLoader" style="margin-top: 10%" /> -->
    <div v-show="!showLoader">
      <Header :key="keyHeader" v-if="$route.path !== '/'" />
      <router-view
        @success="keyHeader++, keyFooter++"
        @other="setShowNavigationDrawer"
        :filtersList="filtersList"
      />
      <Footer :key="keyFooter" />
      <mobile-menu
        v-if="$vuetify.breakpoint.xs"
        @other="setShowNavigationDrawer"
        @setFiltersList="setFiltersList"
      />
      <navigation-drawer-mobile
        v-if="$vuetify.breakpoint.xs && showNavigationDrawer"
        :showNavigationDrawer="showNavigationDrawer"
        @close="showNavigationDrawer = false"
      />
      <ads-modal
        v-if="isShowAdsModal"
        :visible="isShowAdsModal"
        @close="isShowAdsModal = false"
      />
    </div>
    <accept-cookies
      v-if="not_accept_cookie"
      @accept="not_accept_cookie = false"
    />
    <div style="position: fixed;" :style="$vuetify.breakpoint.smAndDown ? ' bottom: 60px; right: 0px; z-index: 101;' : ' bottom: 10px; right: 20px'">
      <v-speed-dial
        v-model="fab"
        :bottom="true"
        :right="true"
        direction="top"
        :open-on-hover="true"
        transition="v-expand-x-transition"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" style="background: #fff !important" dark fab>
            <v-icon v-if="fab" color="#085895"> mdi-close </v-icon>
            <v-icon v-else color="#085895"> mdi-message</v-icon>
          </v-btn>
        </template>
        <a
          v-for="item in socialNetworks"
          :key="item.id"
          :href="item.value"
          target="_blank"
          class="mr-2"
          :aria-label="item.key"
        >
          <v-btn
            fab
            dark
            small
            color="green"
            style="
              background-color: white !important;
              padding: 10px;
              border-radius: 50%;
            "
            width="60px"
            height="60px"
          >
            <img
              :src="item?.image_id.path"
              :alt="item.key"
              style="width: 40px; height: 40px; object-fit: cover"
            />
          </v-btn>
        </a>
      </v-speed-dial>
    </div>
  </v-app>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import mobileMenu from "@/components/mobileMenu";
//import Loader from "./components/UI/Loader.vue";
import navigationDrawerMobile from "./components/UI/navigationDrawerMobile.vue";
import { mapGetters, mapActions } from "vuex";
import contentService from "./requests/admin/contentService";
import BusLoading from "./components/UI/busLoading.vue";
import authService from "./requests/admin/authService";
import AdsModal from "./components/forUser/adsModal.vue";
import AcceptCookies from "./components/acceptCookies.vue";
import settingsService from "@/requests/admin/settingsService";
export default {
  name: "App",
  components: {
    Header,
    Footer,
    mobileMenu,
    navigationDrawerMobile,
    //Loader,
    BusLoading,
    AdsModal,
    AcceptCookies,
  },
  data: () => ({
    showNavigationDrawer: false,
    showLoader: false,
    isShowAdsModal: false,
    not_accept_cookie: true,
    fab: false,
    keyHeader: 1,
    keyFooter: 2,
    filtersList: [],
    socialNetworks: [],
    tokenLifeTime: 59,
  }),
  mounted() {
    if (localStorage.getItem("cookies_yes") == "yes") {
      this.not_accept_cookie = false;
    }
    if (this.loggedUser) {
      this.refreshToken();
    }
    //setTimeout(() => {
    //   if (localStorage.getItem("isShowAdsModal") !== "true") {
    //     this.isShowAdsModal = true;
    //     localStorage.setItem("isShowAdsModal", "true");
    //   }
    // }, 2000);
    setTimeout(this.setShowLoader, 1500);
    this.getContent();
    this.getSettings();
  },
  methods: {
    ...mapActions(["updateInfoLogged"]),
    async onidle() {
      this.$store.commit("clearUserLogged");
      this.$router.push("/");
    },
    setShowNavigationDrawer() {
      this.showNavigationDrawer = true;
    },
    setShowLoader() {
      this.showLoader = false;
    },
    setFiltersList(filtersList) {
      this.filtersList = filtersList;
    },
    async getContent() {
      let response = await contentService.getContents();
      localStorage.setItem(
        `return_conditions`,
        JSON.stringify(
          response.data.filter((res) => res.section == "return_conditions")[0]
            .content
        )
      );
      localStorage.setItem(
        `baggage_transportation_conditions`,
        JSON.stringify(
          response.data.filter(
            (res) => res.section == "baggage_transportation_conditions"
          )[0].content
        )
      );
      localStorage.setItem(
        `additional_conditions`,
        JSON.stringify(
          response.data.filter(
            (res) => res.section == "additional_conditions"
          )[0].content
        )
      );
    },
    async getSettings() {
      await settingsService.getSettingList().then((res) => {
        if (res.status == "Success") {
          this.socialNetworks = res.data.filter(
            (data) => data.type == "socials"
          );
        }
      });
    },
    async refreshToken() {
      let tokenLifeTime = 1000 * 60 * this.tokenLifeTime;
      setInterval(async () => {
        await authService.refreshToken();
      }, tokenLifeTime);
    },
  },
  watch: {
    "$route.path": {
      deep: true,
      handler() {
        if (this.$route.path == "/other") {
          this.setShowNavigationDrawer();
        }
      },
    },
    loggedUser: {
      handler() {
        if (this.loggedUser) {
          this.refreshToken();
        }
      },
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
  },
  created() {
    window.addEventListener("beforeunload", function () {
      localStorage.time = new Date();
    });
    if (localStorage.time) {
      let date = new Date();
      let diff = Math.abs(date - new Date(localStorage.time));
      if (this.loggedUser) {
        if (diff * 0.001 > this.loggedUser.timeout) {
          this.$store.commit("clearUserLogged");
          this.$router.push("/");
        }
        localStorage.removeItem("time");
      }
    }
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  overflow-x: hidden;
}
</style>
