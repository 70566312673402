<template>
  <div>
    <!-- <v-col v-if="$vuetify.breakpoint.smAndDown" class="px-0">
      <ticket-card-mobile
        v-for="ticket in ticketList"
        :key="ticket.id"
        :ticket="ticket"
        :orderType="orderType"
      />
    </v-col> 
    <v-col v-else>-->
      <ticket-card
        v-for="ticket in ticketList"
        :key="ticket.id"
        :ticket="ticket"
        @close="$emit('close')"
        @getOrders="$emit('getOrders')"
        :orderType="orderType"
      />
    <!-- </v-col> -->
  </div>
</template>

<script>
import ticketCard from "./ticketCard.vue";
//import TicketCardMobile from "./mobile/ticketCardMobile.vue";
export default {
  components: { ticketCard, 
    //TicketCardMobile 
  },
  props: {
    ticketList: {
      require: true,
    },
    orderType: {
      require: true,
    },
  },
};
</script>

<style>
</style>