<template>
  <div>
    <error-snackbar v-if="showErrorSnackbar" :snackbarText="snackbarText" />
    <v-card
      width="600px"
      height="500px"
      style="align-items: center; overflow: hidden"
    >
      <v-row
        no-gutters
        align="center"
        justify="end"
        style="padding: 20px; padding-bottom: 0px"
        ><img
          class="pointer"
          @click="$emit('close')"
          src="../../../assets/img/closeDialogIcon.svg"
          alt="close_icon"
      /></v-row>
      <v-col style="text-align: center" class="py-0">
        <p class="modalTitle" style="margin-top: 20px">Відновлення паролю</p>
        <p class="modalSubtitle">
          Забули пароль? Не проблема - відновіть його зараз!
        </p>
        <v-row
          no-gutters
          justify="center"
          class="mb-10"
          style="margin-top: 42px"
        >
          <v-col class="px-0 py-0" cols="10" style="margin-bottom: 10px">
            <v-text-field
              color="#085895"
              class="rounded-0"
              label="Введіть ваш Email"
              type="email"
              dense
              outlined
              v-model="email"
              :error-messages="emailError"
            />
            <v-btn
              color="#085895"
              width="200px"
              height="35px"
              dark
              @click="sendEmail"
              >Скинути пароль</v-btn
            >
          </v-col>
          <v-btn
            text
            style="
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              text-transform: none;
              letter-spacing: 0.1em;
              color: #50616e;
            "
            @click="$emit('close')"
            >Скасувати</v-btn
          >
        </v-row>
      </v-col>
    </v-card>
  </div>
</template>
  
  <script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import authService from "@/requests/admin/authService";
import errorSnackbar from "@/components/UI/errorSnackbar.vue";
export default {
  mixins: [validationMixin],
  components: {
    errorSnackbar,
  },
  data: () => ({
    email: "",
    showErrorSnackbar: false,
    snackbarText: "",
  }),
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    async sendEmail() {
      this.$v.$touch();
      this.showErrorSnackbar = false;
      if (!this.$v.$invalid) {
        let email = new FormData();
        email.append("email", this.email);
        await authService
          .resetPassword(email)
          .then(() => {
            localStorage.setItem("userEmail", this.email);
            this.$emit("successSendEmail", this.email);
          })
          .catch(() => {
            this.snackbarText = "Користувача з таким email не існує";
            this.showErrorSnackbar = true;
          });
      }
    },
  },
  computed: {
    emailError() {
      const errors = [];
      if (!this.$v.email.$dirty) {
        return errors;
      }
      !this.$v.email.required && errors.push("Поле email обов'язкове");
      if (!this.$v.email.email) {
        errors.push("Введено некоректний email");
        return errors;
      }
      return errors;
    },
  },
};
</script>
  
  <style>
</style>