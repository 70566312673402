<template>
  <v-dialog v-model="visibility" width="600px" height="600px" persistent>
    <div class="backgroundModal">
      <v-row no-gutters justify="end">
        <img
          src="@/assets/img/closeIcon.svg"
          alt="close icon"
          style="cursor: pointer; user-select: none"
          @click="$emit('close')"
        />
      </v-row>
      <h2
        style="
          color: #eee;
          text-align: center;
          font-family: Roboto;
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        "
        :style="$vuetify.breakpoint.smAndDown ? 'font-size: 24px;' : ''"
      >
        Різдвяні знижки
      </h2>
      <p
        style="
          margin: 32px 0px;
          color: #fff;
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 2px;
          text-transform: lowercase;
        "
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'font-size: 16px; margin: 16px 0px;'
            : ''
        "
      >
        <span style="text-transform: capitalize">Купуйте</span> КВИТКИ НА НАШОМУ
        САЙТІ ТА ОТРИМАЙТЕ ЗНИЖКУ
        <span style="color: #c74238; font-weight: 900">-10%</span> ДО НОВОГО
        РОКУ !
      </p>
      <v-row no-gutters justify="center">
        <img
          src="@/assets/img/kerflinImg.png"
          :width="$vuetify.breakpoint.smAndDown ? '230px' : '277px'"
          :height="$vuetify.breakpoint.smAndDown ? '' : '268px'"
          alt="kerflin"
        />
      </v-row>
      <v-btn
        style="
          text-transform: none;
          color: #fff;
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 2px;
          margin-top: 32px;
        "
        width="280px"
        :height="$vuetify.breakpoint.smAndDown ? '40px' : '50px'"
        color="#085895"
        @click="
          $route.path !== '/'
            ? ($router.push('/'), $emit('close'))
            : $emit('close')
        "
        >Пошук квитків</v-btn
      >
    </div>
  </v-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
export default {
  mixins: [modalMixin],
};
</script>

<style scoped>
.backgroundModal {
  background-color: #085895;
  background-image: url("../../assets/img/adsModalBackground.png");
  padding: 20px;
}
</style>