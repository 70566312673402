<template>
  <div style="text-align: center">
    <success-snackbar v-if="showSuccessSnackbar" :snackbarText="snackbarText" />
    <error-snackbar v-if="showErrorSnackbar" :snackbarText="snackbarText" />
    <v-col class="px-0" style="margin-bottom: 100px">
      <v-row no-gutters align="center" justify="start">
        <v-icon color="#243949" style="margin-right: 5px" @click="$emit('back')"
          >mdi-menu-left</v-icon
        >
        <span
          @click="$emit('back')"
          style="
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.1em;
            color: #243949;
          "
          >Назад</span
        >
      </v-row>
      <p
        style="
          font-weight: 500;
          font-size: 20px;
          line-height: 23px;
          letter-spacing: 0.1em;
          margin-top: 50px;
          color: #085895;
        "
      >
        Реєстрація
      </p>
      <v-col>
        <v-text-field
          outlined
          dense
          color="#085895"
          v-model="user.username"
          :append-icon="usernameError.length ? 'mdi-alert-circle-outline' : ''"
          label="Прізвище"
          placeholder="Введіть тут.."
          :error-messages="usernameError"
        />
        <v-text-field
          outlined
          dense
          color="#085895"
          v-model="user.email"
          :append-icon="emailError.length ? 'mdi-alert-circle-outline' : ''"
          label="Email"
          placeholder="Введіть тут.."
          :error-messages="emailError"
        />
        <v-tooltip bottom color="rgba(0, 0, 0, 0.8)" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              v-on="on"
              outlined
              dense
              color="#085895"
              v-model="user.password"
              :append-icon="
                passwordError.length
                  ? 'mdi-alert-circle-outline'
                  : showPassword
                  ? 'mdi-eye-outline'
                  : 'mdi-eye-off-outline'
              "
              label="Пароль"
              placeholder="Введіть тут.."
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              :error-messages="passwordError"
            />
          </template>
          Пароль повинен містити мінімум 8 символів, серед яких хоч одну цифру,
          спецсимвол ($,&, ... і т.п.), велику та маленьку літери
        </v-tooltip>
        <v-text-field
          outlined
          dense
          color="#085895"
          v-model="user.confirm_password"
          :append-icon="
            confirmPasswordError.length
              ? 'mdi-alert-circle-outline'
              : showConfirmPassword
              ? 'mdi-eye-outline'
              : 'mdi-eye-off-outline'
          "
          label="Пароль"
          placeholder="Введіть тут.."
          :type="showConfirmPassword ? 'text' : 'password'"
          @click:append="showConfirmPassword = !showConfirmPassword"
          :error-messages="confirmPasswordError"
          hide-details
        />
        <v-row
          no-gutters
          align="start"
          justify="start"
          class="mb-5"
          style="margin-top: 10px"
        >
          <v-checkbox color="#085895" v-model="isAggre" />
          <v-col cols="10" style="text-align: left" class="px-0 pt-4">
            <span
              style="
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.1em;
                color: #50616e;
                text-align: left;
              "
              >Згоден з
              <span class="linkColor"
                ><router-link
                  to="/rules_and_general_terms"
                  target="_blank"
                  style="text-decoration-line: underline; color: #085895"
                  >Угодою користувача</router-link
                ></span
              >, з
              <span class="linkColor"
                ><router-link
                  to="/rules_and_general_terms"
                  target="_blank"
                  style="text-decoration-line: underline; color: #085895"
                  >Обробкою моїх пересональних</router-link
                ></span
              >
              даних у відпповідніості з
              <span class="linkColor"
                ><router-link
                  to="/rules_and_general_terms"
                  target="_blank"
                  style="text-decoration-line: underline; color: #085895"
                  >Політикою конфіденційності</router-link
                ></span
              ></span
            >
          </v-col>
        </v-row>
      </v-col>
      <v-btn
        width="208px"
        height="39px"
        style="
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.1em;
          color: #ffffff;
        "
        color="#085895"
        @click="signUp"
        >Зареєструватись</v-btn
      >
      <v-row no-gutters justify="center">
        <div style="width: 243px; margin-top: 20px; margin-bottom: 15px">
          <v-row no-gutters align="center" justify="center">
            <v-divider
              width="100px"
              style="border: 0.5px solid rgba(8, 88, 149, 0.2)"
            />
            <output
              style="
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.1em;
                color: #50616e;
                margin: 0px 10px 0px 10px;
              "
              >або</output
            >
            <v-divider
              width="100px"
              style="border: 0.5px solid rgba(8, 88, 149, 0.2)"
            />
          </v-row>
        </div>
      </v-row>
      <v-col>
        <social-btn @close="$emit('close')" />
      </v-col>
      <span
        style="
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.1em;
          color: #085895;
        "
        @click="$emit('goToSignIn')"
      >
        Увійти
      </span>
    </v-col>
  </div>
</template>
  
  <script>
import { validationMixin } from "vuelidate";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import authService from "@/requests/admin/authService";
import errorSnackbar from "@/components/UI/errorSnackbar.vue";
import SuccessSnackbar from "@/components/UI/successSnackbar.vue";
import SocialBtn from "../../../UI/buttons/socialBtn.vue";
export default {
  mixins: [validationMixin],
  components: {
    errorSnackbar,
    SuccessSnackbar,
    SocialBtn,
  },
  data: () => ({
    user: {},
    showErrorSnackbar: false,
    showSuccessSnackbar: false,
    isAggre: false,
    showPassword: false,
    showConfirmPassword: false,
    snackbarText: "",
  }),
  validations: {
    user: {
      username: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
        validUppercase: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          return containsUppercase;
        },
        validLowercase: function (value) {
          const containsLowercase = /[a-z]/.test(value);
          return containsLowercase;
        },
        validNumber: function (value) {
          const containsNumber = /[0-9]/.test(value);
          return containsNumber;
        },
        validSpecial: function (value) {
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return containsSpecial;
        },
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial &&
            minLength
          );
        },
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    ...mapActions(["updateInfo"]),
    async signUp() {
      this.$v.$touch();
      if (!this.$v.$invalid && this.isAggre) {
        let user = new FormData();
        user.append("first_name", this.user.username);
        user.append("email", this.user.email);
        user.append("password", this.user.password);
        await authService
          .signUp(user)
          .then((data) => {
            this.snackbarText = "Користувач успішно створений";
            this.showSuccessSnackbar = true;
            setTimeout(() => {
              this.showSuccessSnackbar = false;
            }, 3000);
            this.updateInfo({
              token: data.authorisation.token,
            });
            this.$emit("goToSignIn");
          })
          .catch((e) => {
            let response = e.response.data;
            if (response.status == "failed") {
              if (
                response.data.email[0] == "The email has already been taken."
              ) {
                this.emailErros.push("Користувач з таким email вже існує");
              }
            }
          });
      }
    },
  },
  computed: {
    usernameError() {
      const errors = [];
      if (!this.$v.user.username.$dirty) {
        return errors;
      } else if (!this.$v.user.username.required) {
        errors.push("");
      }
      return errors;
    },
    emailError() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      }
      !this.$v.user.email.required && errors.push("");
      if (!this.$v.user.email.email) {
        errors.push("");
        return errors;
      }
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.user.password.$dirty) {
        return errors;
      }
      if (!this.$v.user.password.required) {
        errors.push("");
        return errors;
      }
      if (
        (!this.$v.user.password.valid && this.$v.user.password.required) ||
        (!this.$v.user.password.minLength && this.$v.user.password.required)
      ) {
        errors.push("");
        return errors;
      }
      return errors;
    },
    confirmPasswordError() {
      const errors = [];
      if (!this.$v.user.confirm_password.$dirty) {
        return errors;
      }
      if (!this.$v.user.confirm_password.sameAsPassword) {
        errors.push("");
        return errors;
      }
      !this.$v.user.confirm_password.required && errors.push("");
      return errors;
    },
  },
};
</script>
  
  <style>
</style>