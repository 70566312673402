var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"px-0 py-0 text"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('span',{staticClass:"time",staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(_vm.departure_city.arrival))]),_c('div',{staticStyle:{"width":"20px","margin":"0px 8px"}},[_c('v-col',{staticClass:"px-0 py-0"},[_c('div',{staticStyle:{"width":"18px","height":"18px","border-radius":"50%","border":"2px solid #144fa9"}}),_c('div',{staticStyle:{"width":"1px","height":"28px","background":"#989898","margin":"2px 0px","margin-left":"8px"}})])],1),_c('v-col',{staticClass:"px-0 py-0"},[_c('span',[_vm._v(_vm._s(_vm.departure_city?.city?.name)+", "+_vm._s(_vm.departure_city?.bus_station?.address))])])],1),_vm._l((_vm.route.route_cities),function(city){return _c('v-row',{directives:[{name:"show",rawName:"v-show",value:(
      city.city_uuid !== _vm.route.departure &&
      city.city_uuid !== _vm.route.destination
    ),expression:"\n      city.city_uuid !== route.departure &&\n      city.city_uuid !== route.destination\n    "}],key:city.id,staticStyle:{"margin-left":"5px"},attrs:{"no-gutters":""}},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(city?.arrival))]),_c('div',{staticStyle:{"width":"20px","margin":"0px 8px 0px 6px"}},[_c('v-col',{staticClass:"px-0 py-0"},[_c('img',{attrs:{"src":require("@/assets/img/iconsSvg/mapMarker.svg"),"alt":"map marker"}}),_c('div',{staticStyle:{"width":"1px","height":"28px","background":"#989898","margin":"2px 0px","margin-left":"10px"}})])],1),_c('v-col',{staticClass:"px-0 py-0"},[_c('span',[_vm._v(_vm._s(city?.city?.name)+", "+_vm._s(city?.bus_station?.address))])])],1)}),_c('v-row',{attrs:{"no-gutters":"","align":"start"}},[_c('span',{staticClass:"time",staticStyle:{"font-size":"16px"},style:(!_vm.isDetailRoute ? 'margin-top: 18px' : '')},[_vm._v(_vm._s(_vm.arrival_city.arrival))]),_c('div',{staticStyle:{"width":"20px","margin":"0px 8px"}},[_c('v-col',{staticClass:"px-0 py-0"},[(!_vm.isDetailRoute)?_c('div',{staticStyle:{"width":"1px","height":"12px","background":"#989898","margin":"2px 0px","margin-left":"8px"}}):_vm._e(),_c('div',{staticStyle:{"width":"18px","height":"18px","border-radius":"50%","border":"2px solid #144fa9","padding":"2px","display":"grid","place-items":"center"}},[_c('div',{staticStyle:{"width":"10px","height":"10px","background":"#144fa9","border-radius":"50%"}})])])],1),_c('v-col',{staticClass:"px-0 py-0",style:(!_vm.isDetailRoute ? 'margin-top: 17px' : '')},[_c('span',[_vm._v(_vm._s(_vm.arrival_city?.city?.name)+", "+_vm._s(_vm.arrival_city?.bus_station?.address))])])],1),_c('v-row',{staticStyle:{"margin-top":"20px"},attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"px-0 py-0 d-flex",attrs:{"cols":_vm.isRoute && !_vm.$vuetify.breakpoint.smAndDown
          ? 7
          : _vm.isRoute && _vm.$vuetify.breakpoint.smAndDown
          ? 12
          : 12}},[_c('img',{staticStyle:{"margin-right":"10px"},attrs:{"src":require("@/assets/img/iconsSvg/warningIcon.svg"),"alt":"warning icon"}}),_c('p',{staticClass:"cardDetailSectionText",staticStyle:{"margin":"0px"}},[_vm._v(" Час вказаний приблизний і залежить від часу проходження кордону та інших обставин ")])])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }