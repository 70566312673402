var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"308px","height":"431px"},model:{value:(_vm.visibility),callback:function ($$v) {_vm.visibility=$$v},expression:"visibility"}},[_c('v-card',{staticStyle:{"padding":"30px"}},[_c('v-col',{staticClass:"px-0 py-0",staticStyle:{"text-align":"center"}},[_c('p',{staticStyle:{"font-weight":"500","font-size":"16px","line-height":"19px","letter-spacing":"0.1em","color":"#122b3e"}},[_vm._v(" Ваш електронний білет ")]),_c('p',{staticStyle:{"font-weight":"400","font-size":"14px","line-height":"16px","text-align":"center","letter-spacing":"0.1em","color":"#243949"}},[_vm._v(" Відскануйте QR-код ")]),_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('div',{staticStyle:{"width":"200px","height":"200px"}},[_c('VueQRCodeComponent',{attrs:{"text":_vm.text,"size":200,"color":"#583CA9","render-as":"svg"}})],1)]),_c('v-row',{staticClass:"px-5",staticStyle:{"margin-top":"5px","margin-bottom":"5px"},attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-divider',{staticClass:"mr-2",staticStyle:{"width":"80px"}}),_c('span',{staticStyle:{"font-weight":"400","font-size":"12px","line-height":"14px","letter-spacing":"0.1em","color":"#50616e"}},[_vm._v("або")]),_c('v-divider',{staticClass:"ml-2",staticStyle:{"width":"80px"}})],1),_c('v-btn',{staticStyle:{"font-size":"14px","line-height":"16px","letter-spacing":"0.1em","color":"#243949","background":"#ffffff","box-shadow":"0px 4px 4px rgba(0, 0, 0, 0.15)","border-radius":"10px","text-transform":"none","border":"1px solid #085895"},style:(_vm.sendEmail
            ? 'background: linear-gradient(90deg, rgba(88,60,169,1) 12%, rgba(8,88,149,1) 98%)!important; color: white;'
            : ''),attrs:{"width":"247px","height":"36px","disabled":_vm.sendEmail,"loading":_vm.isLoadingEmail},on:{"click":_vm.sendQrToEmail}},[(!_vm.sendEmail)?_c('span',[_vm._v("Надіслати на Email")]):_c('span',{staticStyle:{"color":"white"}},[_vm._v("Надіслано")])]),_c('v-btn',{staticStyle:{"font-size":"14px","line-height":"16px","letter-spacing":"0.1em","color":"#243949","background":"#ffffff","box-shadow":"0px 4px 4px rgba(0, 0, 0, 0.15)","border-radius":"10px","text-transform":"none","margin-top":"10px","border":"1px solid #085895"},style:(_vm.downloadPDF
            ? 'background: linear-gradient(90deg, rgba(88,60,169,1) 12%, rgba(8,88,149,1) 98%)!important; color: white'
            : ''),attrs:{"width":"247px","height":"36px","disabled":_vm.downloadPDF,"loading":_vm.isLoadingPdf},on:{"click":_vm.downloadQrToPDF}},[(!_vm.downloadPDF)?_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('span',[_vm._v("Завантажити PDF квиток ")]),_c('img',{staticStyle:{"margin-left":"10px"},attrs:{"src":require("@/assets/img/downloadIcon.svg"),"alt":"download"}})]):_c('span',{staticStyle:{"color":"white"}},[_vm._v("Завантажено")])],1)],1)],1),_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":true,"preview-modal":false,"paginate-elements-by-height":1400,"filename":`Квиток ${_vm.ticket?.departure_info?.name} - ${
      _vm.ticket?.arrival_info?.name
    } ${new Date(
      _vm.addMinutesToDate(
        _vm.ticket?.trip_info?.departure_date,
        _vm.ticket?.departure_stations?.time_from_start
      )
    ).toLocaleDateString('uk-UA', { month: 'short', day: 'numeric' })}`,"pdf-quality":2,"manual-pagination":false,"pdf-format":"a4","pdf-margin":100,"pdf-orientation":"portrait"}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('pdf-ticket',{attrs:{"id":"pdfTicket","ticket":_vm.ticket,"isPdf":_vm.isPdf,"text":_vm.text,"indexTicket":_vm.indexTicket}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }