<template>
  <v-col
    class="px-0 py-0"
    style="
      color: #1b1b1b;
      font-family: Roboto;
      font-style: normal;
      line-height: normal;
    "
  >
    <v-row no-gutters align="center">
      <div
        class="section"
        :class="activeSection == 'route' ? 'activeSection' : ''"
        @click="activeSection = 'route'"
      >
        Маршрут
      </div>
      <div
        class="section"
        :class="activeSection == 'passangers' ? 'activeSection' : ''"
        @click="activeSection = 'passangers'"
      >
        Пасажири
      </div>
      <div
        class="section"
        :class="activeSection == 'conditions' ? 'activeSection' : ''"
        @click="activeSection = 'conditions'"
      >
        Умови перевезення
      </div>
      <div
        class="section"
        :class="activeSection == 'bus' ? 'activeSection' : ''"
        @click="activeSection = 'bus'"
      >
        Автобус
      </div>
    </v-row>
    <v-expand-transition>
      <v-col v-if="activeSection == 'route'" class="px-0 py-0">
        <p class="contentTitle">Маршрут</p>
        <v-row no-gutters align="center">
          <span class="contentSubtitle"
            >{{ ticket?.trip_info?.route_info?.departure_info?.name }} -
            {{ ticket?.trip_info?.route_info?.destination_info?.name }}</span
          >
          <span
            class="detailText"
            :style="$vuetify.breakpoint.smAndDown ? 'margin-left: 0px; margin-top: 4px;' : ''"
            @click="
              $router.push(
                `/routes/${ticket.trip_info?.route_info?.departure_info?.name}/${ticket.trip_info?.route_info.destination_info?.name}/${ticket.trip_info?.route_info.slug}/${ticket.trip_info?.route_info.id}`
              )
            "
            >Детальний маршрут</span
          >
        </v-row>
        <p class="contentTitle">В дорозі</p>
        <v-row no-gutters align="center">
          <span class="content">
            В дорозі
            {{
              setDifferentTime(
                addMinutesToDate(
                  ticket?.trip_info?.departure_date,
                  ticket?.departure_stations?.time_from_start
                ),
                addMinutesToDate(
                  ticket?.trip_info?.departure_date,
                  ticket?.arrival_stations?.time_from_start
                )
              )
            }}
          </span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                color="#989898"
                size="14px"
                style="margin-left: 4px"
                >mdi-help-circle-outline</v-icon
              >
            </template>
            <span>Час відправлення та прибуття місцевий</span>
          </v-tooltip>
        </v-row>

        <p class="contentTitle">Платформа</p>
        <span class="content">
          {{
            ticket?.trip_info?.route_info.route_cities.find(
              (city) => city.city_uuid == ticket?.departure_city
            )?.platform
          }}
        </span>
        <p class="contentTitle">Детальний маршрут</p>
        <v-col cols="12" xl="6" lg="6" md="6" sm="12" class="px-0 py-0">
          <detail-route-form
            style="margin-top: 12px"
            :route="ticket?.trip_info?.route_info"
          />
        </v-col>
      </v-col>
    </v-expand-transition>
    <v-expand-transition>
      <v-col v-if="activeSection == 'passangers'">
        <passanger-item
          v-for="(passanger, index) in ticket.ticket"
          :key="passanger.id"
          :passanger="passanger"
          @downloadTicketByIndex="$emit('downloadTicketByIndex', index)"
        />
      </v-col>
    </v-expand-transition>
    <v-expand-transition>
      <v-col v-if="activeSection == 'conditions'" class="px-0 py-0">
        <p class="contentTitle">Умови повернення</p>
        <div
          class="content"
          style="color: #4b5262"
          v-html="return_conditions"
        ></div>
        <p class="contentTitle">Багаж</p>
        <div
          class="content"
          style="color: #4b5262"
          v-html="baggage_transportation_conditions"
        ></div>
        <p class="contentTitle">Додатково</p>
        <div
          class="content"
          style="color: #4b5262"
          v-html="additional_conditions"
        ></div>
      </v-col>
    </v-expand-transition>
    <v-expand-transition>
      <v-col v-if="activeSection == 'bus'" class="px-0 py-0">
        <p class="contentTitle">Модель</p>
        <v-row no-gutters align="center">
          <span class="contentSubtitle">{{
            ticket?.trip_info?.bus_info?.model
          }}</span>
          <!-- <span class="detailText">Детальніше про автобус</span> -->
        </v-row>
        <p class="contentTitle">Сервіси</p>
        <v-row no-gutters>
          <div
            v-for="item in service"
            :key="item.id"
            style="text-align: center"
          >
            <v-tooltip
              bottom
              v-if="services[item.key] == 1"
              style="border: 5px solid green"
            >
              <template v-slot:activator="{ on, attrs }">
                <div style="width: 50px; text-align: center">
                  <img
                    :src="require(`@/assets/img/busServiceIcon${item.img}`)"
                    width="30px"
                    height="30px"
                    v-bind="attrs"
                    v-on="on"
                  />
                </div>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </div>
        </v-row>
      </v-col>
    </v-expand-transition>
    <button class="hideBtn" @click="$emit('close')">
      Сховати
      <v-icon style="margin-left: 8px" color="#1B1B1B" size="16px"
        >mdi-chevron-up</v-icon
      >
    </button>
  </v-col>
</template>

<script>
import diffrentTimeMixin from "../../../mixins/diffrentTimeMixin";
import detailRouteForm from "../../UI/detailRouteForm.vue";
import PassangerItem from "./passangerItem.vue";
export default {
  components: { detailRouteForm, PassangerItem },
  mixins: [diffrentTimeMixin],
  data: () => ({
    activeSection: "route",
    service: [
      {
        id: 1,
        img: "/toiletIcon.png",
        title: "Туалет",
        key: "toilet",
        enters: false,
      },
      {
        id: 2,
        img: "/coffeIcon.png",
        title: "Харчування",
        key: "supply",
        enters: false,
      },
      {
        id: 3,
        img: "/electricOutletIcon.png",
        title: "Розетки",
        key: "socket",
        enters: false,
      },
      {
        id: 4,
        img: "/coldIcon.png",
        title: "Клімат контроль",
        key: "climate",
        enters: true,
      },
      {
        id: 5,
        img: "/wifiIcon.png",
        title: "Wi-Fi",
        key: "wifi",
        enters: false,
      },
      {
        id: 6,
        img: "/seriesIcon.png",
        title: "Перегляд TV",
        key: "tv",
        enters: false,
      },
      {
        id: 7,
        img: "/vipIcon.png",
        title: "VIP",
        key: "vip",
        enters: false,
      },
      {
        id: 8,
        img: "/euro5Icon.png",
        title: "Екологічність",
        key: "ecology",
        enters: false,
      },
    ],
    return_conditions: localStorage
      .getItem("return_conditions")
      .replaceAll('"', ""),
    baggage_transportation_conditions: localStorage
      .getItem("baggage_transportation_conditions")
      .replaceAll('"', ""),
    additional_conditions: localStorage
      .getItem("additional_conditions")
      .replaceAll('"', ""),
  }),
  props: {
    services: {
      require: true,
    },
    ticket: {
      require: false,
    },
  },
};
</script>

<style scoped>
.section {
  padding: 12px;
  color: #1b1b1b;
  font-size: 14px;
  font-weight: 400;
  background: transparent;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
}
.activeSection {
  background: rgba(8, 88, 149, 0.1);
  color: #085895;
}
.content {
  color: #1b1b1b;
  font-size: 14px;
  font-weight: 400;
}
.contentTitle {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 8px;
}
.contentSubtitle {
  font-size: 14px;
  font-weight: 500;
}
.detailText {
  color: #989898;
  font-size: 14px;
  font-weight: 400;
  text-decoration-line: underline;
  margin-left: 10px;
  cursor: pointer;
  user-select: none;
}
.hideBtn {
  border-radius: 10px;
  background: rgba(20, 79, 169, 0.1);
  padding: 8px 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 12px 0px;
}
</style>