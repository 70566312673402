<template>
  <div>
    <v-dialog
      persistent
      v-model="visibility"
      :width="$vuetify.breakpoint.xs ? '90%' : '600px'"
      style="overflow: hidden"
    >
      <error-snackbar v-if="showErrorSnackbar" :snackbarText="snackbarText" />
      <v-card
        :width="$vuetify.breakpoint.xs ? '90%' : '900px'"
        height="600px"
        style="border-radius: 10px"
        v-on:keyup.enter="signIn"
      >
        <!-- <modal-header
          title="Вхід користувача"
          @close="$emit('close')"
          :showCloseIcon="true"
        /> -->
        <v-card style="overflow: hidden" height="600px" width="600px">
          <v-row
            no-gutters
            align="center"
            justify="end"
            style="padding: 20px; padding-bottom: 0px"
            ><img
              class="pointer"
              @click="$emit('close')"
              src="../../../../assets/img/closeDialogIcon.svg"
              alt="close_icon"
              width="20px"
          /></v-row>
          <v-row justify="center" style="margin-top: 20px">
            <v-col cols="10" lg="10" md="10" sm="10" xl="10" class="py-0">
              <p class="modalTitle">Вхід</p>
              <p class="modalSubtitle">
                Щоб продовжити купівлю квитків, увійдіть в кабінет користувача
              </p>
              <v-text-field
                dense
                outlined
                v-model.trim="user.email"
                color="7B8892"
                placeholder="Email"
                autocomplete="email"
                :error-messages="emailError"
                @blur="$v.user.email.$touch()"
              />
              <v-text-field
                dense
                outlined
                v-model.trim="user.password"
                color="7B8892"
                placeholder="Пароль"
                :type="showPassword ? 'text' : 'password'"
                :error-messages="passwordError"
                @blur="$v.user.password.$touch()"
                :hide-details="!passwordError.length"
                :append-icon="
                  showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                "
                @click:append="showPassword = !showPassword"
              />
              <v-row no-gutters align="center" class="py-0">
                <v-row no-gutters align="center" justify="start" class="py-0">
                  <v-checkbox color="#085895" v-model="rememberMe" />
                  <span>Запам'ятати мене</span>
                </v-row>
                <v-row no-gutters align="center" justify="end" class="py-0">
                  <span
                    @click="$emit('resetPassword')"
                    class="signInTextStyle pointer"
                    >Забули пароль</span
                  >
                </v-row>
              </v-row>
              <v-btn
                style="text-transform: none; font-size: 16px"
                width="208px"
                height="39px"
                class="white--text"
                color="#085895"
                @click="signIn"
                >Увійти</v-btn
              >
              <!-- <v-row no-gutters justify="center" class="mt-5">
                <a href="" target="_blank">
                  <img
                    src="../../../../assets/img/google-icon.svg"
                    alt="google logo"
                    width="40px"
                    height="40px"
                    style="object-fir: cover; mix-blend-mode: darken"
                  />
                </a>
              </v-row> -->
              <v-row no-gutters align="center">
                <v-col>
                  <v-divider class="mt-5 mb-5 mr-5 px-15 divider" />
                </v-col>
                <p class="mt-4">або</p>
                <v-col>
                  <v-divider class="mt-5 mb-5 ml-5 px-15 divider" /> </v-col
              ></v-row>
              <social-btn @close="$emit('close')" />
              <div class="mb-5">
                <span
                  class="signInTextStyle pointer"
                  @click="$emit('goToSignUp')"
                  >Зареєструватись</span
                >
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import modalHeader from "@/components/UI/modalHeader.vue";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import authService from "@/requests/admin/authService";
import { mapActions } from "vuex";
import errorSnackbar from "@/components/UI/errorSnackbar";
import modalMixin from "@/mixins/modalMixin";
import SocialBtn from "@/components/UI/buttons/socialBtn.vue";
export default {
  mixins: [validationMixin, modalMixin],
  components: {
    // modalHeader,
    errorSnackbar,
    SocialBtn,
  },
  data: () => ({
    user: {},
    rememberMe: false,
    snackbarText: "",
    showErrorSnackbar: false,
    showPassword: false,
  }),
  validations: {
    user: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(["updateInfoLogged"]),
    async signIn() {
      this.showErrorSnackbar = false;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let user = new FormData();
        user.append("email", this.user.email);
        user.append("password", this.user.password);
        let response = await authService.signIn(user).catch(() => {
          this.snackbarText = "Email або пароль введено неправильно";
          this.showErrorSnackbar = true;
        });
        let timeout = 2 * 3600;
        if (this.rememberMe) {
          timeout = 12 * 3600;
        }
        if (response.status == "success") {
          this.updateInfoLogged({
            email: this.user.email,
            password: this.user.password,
            phone_number: response.user.phone_number,
            token: response.authorisation.token,
            role_id: response.user.role.role_id,
            first_name: response.user.first_name,
            last_name: response.user.last_name,
            timeout: timeout,
          });
          this.$emit("close");
          sessionStorage.clear();
          if (this.$route.name !== "ticket_booking") {
            this.$router.push({ name: "main" });
          }
        }
      }
    },
  },
  computed: {
    emailError() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      }
      !this.$v.user.email.required && errors.push("Поле email обов'язкове");
      if (!this.$v.user.email.email) {
        errors.push("Введено некоректний email");
        return errors;
      }
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.user.password.$dirty) {
        return errors;
      }
      !this.$v.user.password.required && errors.push("Поле пароль обов'язкове");
      return errors;
    },
  },
};
</script>

<style scoped>
</style>