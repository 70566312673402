<template>
  <v-col class="px-0 py-0 text">
    <v-row no-gutters>
      <span class="time" style="font-size: 16px">{{ departure_city.arrival }}</span>
      <div style="width: 20px; margin: 0px 8px">
        <v-col class="px-0 py-0">
          <div
            style="
              width: 18px;
              height: 18px;
              border-radius: 50%;
              border: 2px solid #144fa9;
            "
          ></div>
          <div
            style="
              width: 1px;
              height: 28px;
              background: #989898;
              margin: 2px 0px;
              margin-left: 8px;
            "
          />
        </v-col>
      </div>
      <v-col class="px-0 py-0">
        <span>{{ departure_city?.city?.name }}, {{ departure_city?.bus_station?.address }}</span>
      </v-col>
    </v-row>
    <!-- <button
      class="detailBtn"
      v-if="!isDetailRoute"
      @click="isDetailRoute = true"
    >
      <v-icon style="margin-right: 8px">mdi-chevron-up</v-icon>
      <v-icon style="margin-right: 12px">mdi-chevron-down</v-icon>
      Детальний маршрут
    </button> -->
    <v-row
      no-gutters
      v-for="city in route.route_cities"
      :key="city.id"
      style="margin-left: 5px"
      v-show="
        city.city_uuid !== route.departure &&
        city.city_uuid !== route.destination
      "
    >
      <span class="time">{{ city?.arrival }}</span>
      <div style="width: 20px; margin: 0px 8px 0px 6px">
        <v-col class="px-0 py-0">
          <img src="@/assets/img/iconsSvg/mapMarker.svg" alt="map marker" />
          <div
            style="
              width: 1px;
              height: 28px;
              background: #989898;
              margin: 2px 0px;
              margin-left: 10px;
            "
          />
        </v-col>
      </div>
      <v-col class="px-0 py-0">
        <span>{{ city?.city?.name }}, {{ city?.bus_station?.address }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters align="start">
      <span
        class="time"
        style="font-size: 16px"
        :style="!isDetailRoute ? 'margin-top: 18px' : ''"
        >{{arrival_city.arrival}}</span
      >
      <div style="width: 20px; margin: 0px 8px">
        <v-col class="px-0 py-0">
          <div
            v-if="!isDetailRoute"
            style="
              width: 1px;
              height: 12px;
              background: #989898;
              margin: 2px 0px;
              margin-left: 8px;
            "
          />
          <div
            style="
              width: 18px;
              height: 18px;
              border-radius: 50%;
              border: 2px solid #144fa9;
              padding: 2px;
              display: grid;
              place-items: center;
            "
          >
            <div
              style="
                width: 10px;
                height: 10px;
                background: #144fa9;
                border-radius: 50%;
              "
            ></div>
          </div>
        </v-col>
      </div>
      <v-col
        class="px-0 py-0"
        :style="!isDetailRoute ? 'margin-top: 17px' : ''"
      >
        <span>{{ arrival_city?.city?.name }}, {{ arrival_city?.bus_station?.address }}</span>
      </v-col>
    </v-row>
    <!-- <button
      class="detailBtn"
      @click="isDetailRoute = false"
      v-if="isDetailRoute && !isRoute"
    >
      <v-icon style="margin-right: 12px">mdi-chevron-up</v-icon>
      <span> Сховати</span>
    </button> -->
    <v-row no-gutters align="center" style="margin-top: 20px">
      <v-col
        :cols="
          isRoute && !$vuetify.breakpoint.smAndDown
            ? 7
            : isRoute && $vuetify.breakpoint.smAndDown
            ? 12
            : 12
        "
        class="px-0 py-0 d-flex"
      >
        <img
          src="@/assets/img/iconsSvg/warningIcon.svg"
          alt="warning icon"
          style="margin-right: 10px"
        />
        <p class="cardDetailSectionText" style="margin: 0px">
          Час вказаний приблизний і залежить від часу проходження кордону та
          інших обставин
        </p>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  data: () => ({
    isDetailRoute: true,
    departure_city: {},
    arrival_city: {},
  }),
  props: {
    isRoute: { require: false },
    route: {
      require: true,
    },
  },
  mounted() {
    this.departure_city = this.route.route_cities.find(
      (city) => city.city_uuid == this.route.departure
    );
    this.arrival_city = this.route.route_cities.find(
      (city) => city.city_uuid == this.route.destination
    );
    if (this.isRoute) {
      this.isDetailRoute = true;
    }
  },
};
</script>

<style scoped>
.text {
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.time {
  font-weight: 500;
}
.detailBtn {
  border-radius: 10px;
  background: rgba(20, 79, 169, 0.1);
  padding: 4px 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 12px 0px;
}
</style>