<template>
  <div>
    <v-row justify="center">
      <div class="d-flex align-center" style="min-height: 20vh" v-if="loader">
        <v-progress-circular
          class="mx-auto"
          indeterminate
          color="#085895"
        ></v-progress-circular>
      </div>
      <v-col class="px-0" v-else>
        <v-autocomplete
          background-color="white"
          prepend-inner-icon="mdi-map-marker-outline"
          placeholder="Звідки"
          outlined
          color="#085895"
          dense
          class="rounded-t-lg rounded-b-0 routesList"
          hide-details
          :items="Object.values(startCities)"
          :item-text="(item) => `${item.city_name}, ${item.country_name}`"
          :item-value="'city_uuid'"
          v-model="start_route"
          :error-messages="startRouteError"
          height="50px"
        >
          <template v-slot:prepend-inner>
            <img
              class="mt-2"
              src="@/assets/img/mobileMenu/placeIcon.svg"
              alt="місце"
              width="18px"
              height="20px"
            />
          </template>
          <template slot="item" slot-scope="{ item }">
            <span style="font-size: 16px; line-height: 19px; color: #243949">{{
              item.city_name
            }}</span
            >,
            <span
              style="
                font-size: 16px;
                line-height: 19px;
                color: rgb(111, 111, 111);
              "
              >{{ item.country_name }}</span
            >
          </template>
        </v-autocomplete>
        <v-autocomplete
          background-color="white"
          prepend-inner-icon="mdi-map-marker-outline"
          placeholder="Куди"
          outlined
          dense
          color="#085895"
          class="rounded-b-0 rounded-t-0 routesList"
          :items="Object.values(nextCities)"
          :item-text="(item) => `${item.city_name}, ${item.country_name}`"
          :item-value="'city_uuid'"
          v-model="end_route"
          :disabled="nextCities.length == 0"
          hide-details
          :error-messages="endRouteError"
          height="50px"
        >
          <template v-slot:prepend-inner>
            <img
              class="mt-2"
              src="@/assets/img/mobileMenu/placeIcon.svg"
              alt="місце"
              width="18px"
              height="20px"
            />
          </template>
          <template slot="item" slot-scope="{ item }">
            <span style="font-size: 16px; line-height: 19px; color: #243949">{{
              item.city_name
            }}</span
            >,
            <span
              style="
                font-size: 16px;
                line-height: 19px;
                color: rgb(111, 111, 111);
              "
              >{{ item.country_name }}</span
            >
          </template>
        </v-autocomplete>
        <v-row no-gutters align="center">
          <v-col class="px-0 py-0">
            <v-text-field
              v-if="$vuetify.breakpoint.smAndDown"
              background-color="white"
              prepend-inner-icon="mdi-calendar"
              placeholder="Сьогодні"
              outlined
              dense
              color="#085895"
              class="rounded-0"
              @click="showChooseDate = true"
              :value="
                date
                  ? new Date(date).toLocaleDateString('uk-UA', {
                      month: 'short',
                      day: 'numeric',
                    })
                  : ''
              "
              :error-messages="dateError"
              hide-details
              height="50px"
            >
              <template v-slot:prepend-inner>
                <img
                  @click="showChooseDateMenu = true"
                  class="pointer"
                  src="@/assets/img/mobileMenu/calendarIcon.svg"
                  alt="календар"
                  width="18px"
                  height="18px"
                  style="margin-top: 7px"
                />
              </template>
            </v-text-field>
            <v-menu
              v-else
              ref="menu"
              v-model="showChooseDateMenu"
              bottom
              offset-y
              nudge-left="70px"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  background-color="white"
                  prepend-inner-icon="mdi-calendar"
                  placeholder="Сьогодні"
                  outlined
                  dense
                  color="#085895"
                  class="rounded-0"
                  height="50px"
                  :value="
                    date
                      ? new Date(date).toLocaleDateString('uk-UA', {
                          month: 'short',
                          day: 'numeric',
                        })
                      : ''
                  "
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                  :error-messages="dateError"
                >
                  <template v-slot:prepend-inner>
                    <img
                      @click="showChooseDateMenu = true"
                      class="pointer"
                      style="padding-top: 7px"
                      src="@/assets/img/mobileMenu/calendarIcon.svg"
                    /> </template
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                color="#085895"
                class="disabledPickerBtn"
                no-title
                locale="uk"
                :min="
                  new Date(
                    new Date().getTime() +
                      1 * 60 * 60 * 1000 -
                      new Date().getTimezoneOffset() * 60000
                  )
                    .toISOString()
                    .substr(0, 10)
                "
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="px-0 py-0" style="text-align: center"
            ><v-text-field
              v-if="$vuetify.breakpoint.smAndDown"
              background-color="white"
              prepend-inner-icon="mdi-account-outline"
              placeholder="1"
              outlined
              color="#085895"
              v-model="quantity_people"
              dense
              :error-messages="quantityPeopleError"
              hide-details
              @click="showQuantityPassangerModal = true"
              @click:prepend-inner="showQuantityPassangerModal = true"
              class="rounded-0 quantityPeopleTextField"
              height="50px"
            >
              <template v-slot:prepend-inner>
                <v-icon class="mt-1">mdi-account-outline</v-icon>
              </template>
            </v-text-field>
            <v-menu
              v-else
              v-model="showQuantityPeopleMenu"
              left
              offset-y
              width="261px"
              :close-on-content-click="isClose"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  background-color="white"
                  placeholder="1"
                  outlined
                  dense
                  color="#085895"
                  v-model="quantity_people"
                  class="rounded-0"
                  height="50px"
                  @click="isClose = false"
                  hide-details
                  :error-messages="quantityPeopleError"
                >
                  <template v-slot:prepend-inner>
                    <v-icon
                      @click="showQuantityPeopleMenu = true"
                      class="pointer"
                      color="#085895"
                      arria-label="Кількість місць"
                      style="padding-top: 5px;"
                      >mdi-account-outline</v-icon
                    ></template
                  ></v-text-field
                >
              </template>
              <v-card width="261px" style="padding: 15px 30px 15px 30px">
                <v-row no-gutters align="center" justify="start">
                  <v-col cols="6" style="text-align: left"
                    ><span class="peopleTypeStyle"
                      >Кількість пасажирів:</span
                    ></v-col
                  >
                  <v-icon
                    :color="quantity_people == 0 ? '' : '#085895'"
                    @click="
                      quantity_people > 0 ? quantity_people-- : (quantity_people = 0)
                    "
                    >mdi-minus-circle-outline</v-icon
                  >
                  <output
                    style="margin-left: 15px; margin-right: 15px"
                    class="peopleTypeStyle"
                    >{{ quantity_people }}</output
                  >
                  <v-icon color="#085895" @click="quantity_people++"
                    >mdi-plus-circle-outline</v-icon
                  >
                </v-row>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-btn
          width="100%"
          height="50px"
          color="#085895"
          style="border-radius: 0px 0px 10px 10px; background: #085895"
          @click="searchRoutes"
        >
          <span
            style="
              color: #ececec;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            "
            >Пошук</span
          >
        </v-btn>
      </v-col>
      <div
        style="
          width: 40px;
          height: 30px;
          margin-top: 40px;
          cursor: pointer;
          position: absolute;
        "
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'margin-left: 80svh;'
            : 'margin-left: 40svh;'
        "
        @click="reverseItem"
        v-if="!loader"
      >
        <img
          src="@/assets/img/reverseIcon.svg"
          class="mt-2"
          alt="обернути"
          width="30px"
          height="30px"
        />
      </div>
    </v-row>
    <quantity-pasanger-mobile
      v-if="showQuantityPassangerModal"
      :visible="showQuantityPassangerModal"
      :quantity_adult="quantity_people"
      @close="setQuantityPeople"
    />
    <choose-date-mobile
      v-if="showChooseDate"
      :visible="showChooseDate"
      @close="showChooseDate = false"
      @setDate="setDate"
    />
  </div>
</template>

<script>
import quantityPasangerMobile from "@/components/UI/modals/quantityPasangerMobile.vue";
import searchRoutesService from "@/requests/main/searchRoutesService";
import { mapActions, mapGetters } from "vuex";
import ChooseDateMobile from "@/components/UI/modals/chooseDateMobile.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  components: {
    quantityPasangerMobile,
    ChooseDateMobile,
  },
  data: () => ({
    start_route: "",
    end_route: "",
    quantity_people: 1,
    date: "",
    quantityAdult: 1,
    quantityKid: 0,
    showQuantityPassangerModal: false,
    showChooseDate: false,
    showChooseDateMenu: false,
    showQuantityPeopleMenu:false,
    isClose: false,
    startCities: [],
    nextCities: [],
  }),
  validations: {
    start_route: {
      required,
    },
    end_route: {
      required,
    },
    date: {
      required,
    },
    quantity_people: {
      required,
    },
  },
  mounted() {
    this.getStartCities();
  },
  methods: {
    ...mapActions(["updateLoader"]),
    reverseItem(start_route, end_route) {
      if (start_route && end_route) {
        this.updateLoader(true);
        this.start_route = end_route;
        this.end_route = start_route;
      } else if (this.start_route && this.end_route) {
        this.updateLoader(true);
        let old_start_route = this.start_route;
        this.start_route = this.end_route;
        this.end_route = old_start_route;
      }
    },
    setDate(date) {
      this.date = date;
      this.showChooseDate = false;
    },
    hideLoader() {
      this.updateLoader(false);
    },
    async getStartCities() {
      let response = await searchRoutesService.getStartCities();
      this.startCities = response.data.filter((city) => city.city !== null);
      this.hideLoader();
      this.setSearchField();
      this.setStartRouteName();
    },

    async getNextCities(start_route) {
      this.updateLoader(true);
      let response = await searchRoutesService.getNextCities(
        start_route ? start_route : this.start_route
      );
      //let start_cities_type = this.startCities.filter(
      //  (city) => city.city_id == this.start_route
      //)[0].type;
      this.nextCities = response.data.filter(
        (city) => city.city !== null
        //&& city.type !== start_cities_type
      );
      setTimeout(this.hideLoader, 500);
      this.setEndRouteName();
    },
    async searchRoutes() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$route.name !== "ticket_search_page"
          ? this.$router.push(
              `/tickets?departureCity=${this.start_route}&arrivalCity=${
                this.end_route
              }&date=${this.date}&quantity_adult=${parseInt(
                this.quantity_people
              )}`
            )
          : this.$router.push(
              `/tickets?departureCity=${this.start_route}&arrivalCity=${
                this.end_route
              }&date=${this.date}&quantity_adult=${parseInt(
                this.quantity_people
              )}`
            );
        this.hideLoader();
        this.$emit("close");
      }
    },
    setQuantityPeople(quantity_adult) {
      this.quantity_people = quantity_adult;
      this.showQuantityPassangerModal = false;
    },
    setSearchField() {
      if (this.$route.name !== "routes_detail_page") {
        if (Object.values(this.$route.query).length > 0) {
          let route = this.$route.query;
          this.start_route = route.departureCity;
          this.end_route = route.arrivalCity;
          this.date = route.date || new Date().toISOString().substr(0, 10);
          this.quantityAdult = route.quantity_adult;
          this.quantityKid = route.quantity_child;
          if (route.quantity_adult) {
            this.quantity_people = parseInt(route.quantity_adult);
          }
        } else {
          let route = JSON.parse(localStorage.getItem("route_search"));
          this.start_route = route?.departure_city_id;
          this.end_route = route?.arrival_city_id;
          this.date = new Date();
        }
      } else {
        this.start_route = this.startCities.filter(
          (city) => city.city_name == this.$route.params.departureCity
        )?.[0].city_uuid;
        this.end_route = this.startCities.filter(
          (city) => city.city_name == this.$route.params.arrivalCity
        )?.[0].city_uuid;
        this.date = new Date().toISOString().substr(0, 10);
        this.quantityAdult = 1;
        this.quantityKid = 0;
        if (this.$route.params.quantity_adult) {
          this.quantity_people = parseInt(1);
        }
      }
    },
    setStartRouteName() {
      let start_route_name = this.startCities.filter(
        (city) => city.city_uuid == this.start_route
      );
      if (start_route_name && start_route_name.length > 0) {
        start_route_name = start_route_name[0].city_name;
      } else {
        start_route_name = "";
      }
      this.$emit("setStartRouteName", start_route_name);
    },
    setEndRouteName() {
      let end_route_name = this.nextCities.filter(
        (city) => city.city_uuid == this.end_route
      );
      if (end_route_name && end_route_name.length > 0) {
        end_route_name = end_route_name[0].city_name;
      } else {
        end_route_name = "";
      }
      this.$emit("setEndRouteName", end_route_name);
    },
  },
  computed: {
    ...mapGetters(["loader"]),
    startRouteError() {
      const errors = [];
      if (!this.$v.start_route.$dirty) {
        return errors;
      }
      !this.$v.start_route.required &&
        errors.push("Оберіть місце відправлення");
      return errors;
    },
    endRouteError() {
      const errors = [];
      if (!this.$v.end_route.$dirty) {
        return errors;
      }
      !this.$v.end_route.required && errors.push("Оберіть місце прибуття");
      return errors;
    },
    dateError() {
      const errors = [];
      if (!this.$v.date.$dirty) {
        return errors;
      }
      !this.$v.date.required && errors.push("");
      return errors;
    },
    quantityPeopleError() {
      const errors = [];
      if (!this.$v.quantity_people.$dirty) {
        return errors;
      }
      !this.$v.quantity_people.required && errors.push("");
      return errors;
    },
  },
  watch: {
    start_route: {
      handler() {
        if (this.start_route) {
          this.getNextCities(this.start_route);
          this.setStartRouteName();
        }
      },
    },
    end_route: {
      handler() {
        if (this.end_route) {
          this.setEndRouteName();
        }
      },
    },
    newEndRoute: {
      handler() {
        this.end_route = this.newEndRoute;
        this.setEndRouteName();
      },
    },
    "$route.path": {
      deep: true,
      handler() {
        if (Object.keys(this.$route.query).length !== 0) {
          this.setSearchField();
        }
      },
    },
    date: {
      deep: true,
      handler() {
        setTimeout(() => {
          this.showChooseDateMenu = false;
        }, 500);
      },
    },
  },
};
</script>

<style scoped>
.quantityPeopleTextField >>> input {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1em;
  color: #6b7c8a;
}
</style>
<style>
.routesList .v-input__icon {
  display: none !important;
}
</style>