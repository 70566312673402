<template>
  <v-menu
    left
    offset-y
    width="100%"
    v-model="showMenu"
    :close-on-content-click="isClose"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        style="
        width: 100%
          height: 60px;
          background: rgba(8, 88, 149, 0.1);
          border-radius: 10px; 
          padding: 10px 10px 10px 20px;
        "
        v-bind="attrs"
        v-on="on"
      >
        <v-row no-gutters align="center">
          <v-icon large style="margin-right: 20px">mdi-magnify</v-icon>
          <div style="text-align: left; width: calc(75% - 50px)">
            <span
              style="
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.1em;
                color: #243949;
              "
              >{{ start_route_name }}
              <span v-if="end_route_name">-</span>
              {{ end_route_name }}</span
            ><br />
            <span
              style="
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                letter-spacing: 0.1em;
                color: #4c5d6c;
              "
              v-if="$route.query.quantity_adult"
              >{{
                $route.query.date
                  ? new Date($route.query.date).toLocaleDateString("uk-UA", {
                      month: "short",
                      day: "numeric",
                    })
                  : ""
              }},
              {{ parseInt($route.query.quantity_adult) }}
              {{
                parseInt($route.query.quantity_adult) > 1
                  ? "пасажирів"
                  : "пасажир"
              }}</span
            >
          </div>
          <div style="width: 50px;">
            <v-row no-gutters justify="end" align="center">
              <v-btn icon ref="pencil" @click="isClose = false">
                <v-icon> mdi-pencil</v-icon>
              </v-btn>
            </v-row>
          </div>
        </v-row>
      </div>
    </template>
    <v-card width="100%" style="padding: 30px">
      <search-routes-field-mobile
        @setStartRouteName="setStartRouteName"
        @setEndRouteName="setEndRouteName"
        @close="isClose = true"
      />
    </v-card>
  </v-menu>
</template>

<script>
import searchRoutesFieldMobile from "../forUser/Main/mainMobile/searchRoutesFieldMobile.vue";
export default {
  components: { searchRoutesFieldMobile },
  data: () => ({
    isClose: false,
    showMenu: false,
    start_route_name: "",
    end_route_name: "",
  }),
  mounted() {
    this.showMenu = true;
    setTimeout(() => {
      this.showMenu = false;
    }, 10);
    if (this.$route.query.openMenu) {
      setTimeout(() => {
        this.showMenu = true;
      }, 1500);
    }
  },
  methods: {
    setStartRouteName(name) {
      this.start_route_name = name;
    },
    setEndRouteName(name) {
      this.end_route_name = name;
    },
  },
};
</script>

<style>
</style>