<template>
  <v-card
    :min-height="$vuetify.breakpoint.xs ? '100%' : '393px'"
    style="
      border-radius: 5px;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    "
    :style="$vuetify.breakpoint.smAndDown ? 'padding: 20px;' : 'padding: 32px;'"
  >
    <v-row
      no-gutters
      align="center"
      v-if="$vuetify.breakpoint.smAndDown"
      style="margin-bottom: 20px"
    >
      <v-icon
        style="margin-right: 8px"
        color="#1b1b1b"
        @click="$router.push('/')"
        >mdi-menu-left</v-icon
      >
      <span
        @click="$router.push('/')"
        style="
          color: #1b1b1b;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.2px;
        "
        >Назад</span
      >
    </v-row>
    <v-row
      no-gutters
      :justify="$vuetify.breakpoint.smAndDown ? 'start' : 'center'"
    >
      <h2
        class="modalTitle"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'margin-left: 350px'"
      >
        Мої квитки
      </h2>
      <v-row no-gutters justify="end" v-if="!$vuetify.breakpoint.smAndDown">
        <img
          src="@/assets/img/closeIcon.svg"
          alt="close icon"
          class="pointer"
          width="32px"
          height="32px"
          @click="$emit('close')"
        />
      </v-row>
    </v-row>
    <v-row no-gutters align="center">
      <div
        :class="orderType == 'Active' ? 'activeItem' : 'inactiveItem'"
        @click="orderType = 'Active'"
        style="margin-right: 16px"
        class="itemBackground"
        :style="$vuetify.breakpoint.smAndDown ? 'width: calc(50% - 8px);' : ''"
      >
        Активні
      </div>
      <div
        :class="orderType == 'Inactive' ? 'activeItem' : 'inactiveItem'"
        @click="orderType = 'Inactive'"
        class="itemBackground"
        :style="$vuetify.breakpoint.smAndDown ? 'width: calc(50% - 8px);' : ''"
      >
        Неактивні
      </div>
    </v-row>
    <!-- <modal-header
      :showTicketIcon="true"
      :showCloseIcon="$vuetify.breakpoint.xs ? false : true"
      title="Мої квитки"
      @close="$emit('close')"
    /> -->
    <loader v-if="showLoader" />
    <!-- <div
      style="
        dispay: flex;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
      "
      v-else-if="!loggedUser"
    >
      <v-col align-self="center">
        <p class="ticketListEmpty">Увійдіть, щоб переглянути</p>
        <v-btn
          width="214px"
          height="40px"
          class="rounded-lg"
          color="#085895"
          style="
            text-transform: none;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.1em;
            color: #ffffff;
          "
          dark
          @click="$emit('signIn')"
        >
          Увійти
        </v-btn>
      </v-col>
    </div> -->
    <div
      style="
        dispay: flex;
        justify-content: center;
        align-items: center;
        margin-top: 32px;
      "
      v-else-if="myTicketsList.length == 0"
    >
      <v-col align-self="center">
        <img
          src="@/assets/img/emptyListImg.png"
          width="280px"
          height="241px"
          alt="Список порожній"
        />
        <p
          class="ticketListEmpty"
          style="margin-top: 32px; margin-bottom: 12px"
        >
          Кошик порожній
        </p>
        <p
          style="
            color: #828282;
            text-align: center;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          "
        >
          Але це ніколи не пізно виправити
        </p>
      </v-col>
    </div>
    <v-col class="px-0 py-0" style="margin-top: 12px;" v-else>
      <v-col
        class="px-0 py-0"
        v-for="(ticket, key) in myTicketsList"
        :key="ticket.id"
        style="text-align: left;"
      >
        <p
          style="
            color: #1b1b1b;
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 0px;
          "
        >
          {{
            new Date(key).toLocaleDateString("uk-UA", {
              day: "numeric",
              weekday: "long",
              month: "long",
            })
          }}
        </p>

        <ticket-list
          :ticketList="ticket"
          :orderType="orderType"
          @getOrders="getBookingTicket"
          @close="$emit('close')"
          :class="$vuetify.breakpoint.xs ? 'pb-15' : ''"
        />
      </v-col>
    </v-col>

    <v-pagination
      color="#085895"
      v-if="quantityPage > 1"
      :length="quantityPage"
      v-model="page"
    ></v-pagination>
    <!-- <v-col>
      <v-row
        no-gutters
        justify="end"
        v-if="!$vuetify.breakpoint.xs && myTicketsList.length > 0"
      >
        <search-route-btn @close="$emit('close')" />
      </v-row>
    </v-col> -->
    <v-row no-gutters align="center">
      <button
        v-if="!$vuetify.breakpoint.smAndDown"
        class="closeBtn"
        @click="$emit('close')"
      >
        Закрити
      </button>
      <v-spacer />
      <button
        :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : ''"
        class="searchRouteBtn"
        @click="$router.push('/'), $emit('close')"
      >
        Пошук квитків
      </button>
    </v-row>
  </v-card>
</template>
  
  <script>
//import modalHeader from "@/components/UI/modalHeader.vue";
import TicketList from "./ticketList.vue";
//import searchRouteBtn from "@/components/UI/buttons/searchRouteBtn.vue";
import { mapGetters } from "vuex";
import bookingTicketService from "@/requests/main/bookingTicketService";
import Loader from "@/components/UI/Loader.vue";
import diffrentTimeMixin from "../../../mixins/diffrentTimeMixin";
export default {
  mixins: [diffrentTimeMixin],
  components: {
    //modalHeader,
    TicketList,
    //searchRouteBtn,
    Loader,
  },
  data: () => ({
    myTicketsList: [],
    quantityPage: 1,
    page: 1,
    showLoader: true,
    orderType: "Active",
  }),
  mounted() {
    this.getBookingTicket();
  },
  methods: {
    async getBookingTicket() {
      this.showLoader = true;
      await bookingTicketService
        .getOrders(this.orderType.toLocaleLowerCase(), this.page)
        .then((res) => {
          this.myTicketsList = this.groupDataByDate(
            res.data.filter(
              (ticket) => ticket.trip !== null && ticket.departure_info !== null
            )
          );
          console.log("MY TICKET LIST", this.myTicketsList);
          this.quantityPage = res.pagination?.meta?.last_page;
          this.showLoader = false;
        })
        .catch(() => {
          this.showLoader = false;
        });
      setTimeout(() => {
        this.showLoader = false;
      }, 500);
    },
    groupDataByDate(data) {
      const groupedData = {};

      data.forEach((item) => {
        const date = new Date(
          this.addMinutesToDate(
            item?.trip_info?.departure_date,
            item?.trip_info?.route_info?.route_cities.filter(
              (city) => city.city_uuid == item.departure_city
            )?.[0].time_from_start
          )
        );

        if (!groupedData[date]) {
          groupedData[date] = [];
        }

        groupedData[date].push(item);
      });

      return groupedData;
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
  },
  watch: {
    page: {
      handler() {
        this.showLoader = true;
        this.getBookingTicket();
      },
    },
    orderType: {
      handler() {
        this.getBookingTicket();
      },
    },
  },
};
</script>
  
  <style scoped>
@media only screen and (max-width: 1023px) {
  .ticketListEmpty {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.1em;
    color: rgba(18, 43, 62, 0.7);
    padding-bottom: 40px;
  }

  .modalTitle {
    color: #085895;
    text-align: center;
    font-family: Roboto;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
  }
}
.itemBackground {
  border-radius: 5px;
  height: 40px;
  padding: 8px 12px;
  width: max-content;
  display: grid;
  place-items: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  user-select: none;
}
.activeItem {
  background: #085895;
  box-shadow: 0px 4px 4px 0px rgba(20, 158, 81, 0.1);
  color: #eee;
}
.inactiveItem {
  border: 1px solid #e7e7e7;
  background: #fcfcfc;
  color: #1b1b1b;
}
.ticketListEmpty {
  color: #1b1b1b;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.modalTitle {
  color: #085895;
  text-align: center;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.closeBtn {
  height: 40px;
  width: 240px;
  padding: 8px 12px;
  display: grid;
  place-items: center;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  color: #1b1b1b;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.searchRouteBtn {
  height: 40px;
  width: 240px;
  padding: 8px 12px;
  display: grid;
  place-items: center;
  border-radius: 5px;
  background: #085895;
  box-shadow: 0px 2px 10px 0px rgba(60, 60, 60, 0.4);
  color: #eee;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
</style>