<template>
  <v-dialog v-model="visibility" width="600px" height="350px" persistent>
    <!-- <modal-header
      title="Відновлення паролю"
      @close="$emit('close')"
      :showCloseIcon="true"
    /> -->
    <v-card
      height="500px"
      style="text-align: center; padding: 0px 20px 0px 20px"
      v-if="isSuccessSendEmail"
    >
      <v-row
        no-gutters
        align="center"
        justify="end"
        style="padding: 20px 0px 0px 0px;"
        ><img
          class="pointer"
          @click="$emit('close')"
          src="../../../assets/img/closeDialogIcon.svg"
          alt="close_icon"
          width="20px"
      /></v-row>
      <p class="modalTitle" style="padding-top: 20px; margin-bottom: 33px">
        Відновлення паролю
      </p>
      <h2
        style="
          font-weight: 500;
          font-size: 20px;
          line-height: 23px;
          letter-spacing: 0.1em;
          color: #243949;
        "
      >
        Запит успішно прийнятий
      </h2>
      <br />
      <p
        class="mx-auto"
        style="
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.1em;
          color: #243949;
          text-align: left;
        "
      >
        На пошту
        <strong
          >{{ email.substr(0, 3) }}***{{
            email.substr(email.length - 14, email.length - 1)
          }}</strong
        >, вказану під час реєстрації, зараз прийде лист. У ньому буде
        посилання, по якому слід перейти, щоб Ви змогли створити новий
        пароль.<br /><br />
        <strong>Дуже важливо</strong> не забути перевірити папку "СПАМ" у своїй
        поштовій скриньці, якщо лист довго не надходить!
      </p>
    </v-card>
    <div v-else>
      <input-email
        @close="$emit('close')"
        @successSendEmail="successSendEmail"
      />
    </div>
  </v-dialog>
</template>
  
  <script>
import inputEmail from "./inputEmail.vue";
//import modalHeader from "@/components/UI/modalHeader.vue";
import modalMixin from "@/mixins/modalMixin";
export default {
  mixins: [modalMixin],
  components: {
    inputEmail,
    //modalHeader,
  },
  data: () => ({
    isSuccessSendEmail: false,
    email: "",
  }),
  props: {
    visible: {
      require: true,
    },
  },
  methods: {
    successSendEmail(email) {
      this.email = email;
      this.isSuccessSendEmail = true;
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      },
    },
  },
};
</script>
  
  <style>
</style>